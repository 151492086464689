// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconReportAnalytics,
    IconUsers,
    IconDeviceAnalytics
} from '@tabler/icons';

import BiotechIcon from '@mui/icons-material/Biotech';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconUsers,
    IconDeviceAnalytics,
    IconReportAnalytics,
    BiotechIcon,
    GroupWorkIcon,
    MyLocationOutlinedIcon,
    CategoryIcon,
    MapIcon,
    LocalMoviesIcon,
};

// ==============================||  UTILITIES MENU ITEMS  ||============================== //

const utilities = {
    id: 'pages',
    title: 'Masters',
    caption: 'Manage your Data',
    type: 'group',
    children: [
        // {
        //     id: 'user-list',
        //     title: 'Users',
        //     type: 'item',
        //     url: '/pages/users/user-list',
        //     target: false,
        //     icon: icons.IconUsers,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'device-list',
        //     title: 'Devices',
        //     type: 'item',
        //     url: '/pages/devices/device-list',
        //     target: false,
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'lab-list',
        //     title: 'Locations',
        //     type: 'item',
        //     url: '/pages/locations/locations-list',
        //     target: false,
        //     icon: icons.MyLocationOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'department-list',
        //     title: 'Departments',
        //     type: 'item',
        //     url: '/pages/departments/department-list',
        //     target: false,
        //     icon: icons.GroupWorkIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'zone-list',
            title: 'Zones',
            type: 'item',
            url: '/pages/zone/zone-list',
            target: false,
            icon: icons.MapIcon,
            breadcrumbs: false
        },
        {
            id: 'store-categories-list',
            title: 'Store-Categories',
            type: 'item',
            url: '/pages/store-categories/store-categories-list',
            target: false,
            icon: icons.CategoryIcon,
            breadcrumbs: false
        },
        {
            id: 'product-categories-list',
            title: 'Product-Categories',
            type: 'item',
            url: '/pages/product-categories/product-categories-list',
            target: false,
            icon: icons.GroupWorkIcon,
            breadcrumbs: false
        },
        {
            id: 'product-subcategories-list',
            title: 'Product-Sub-Categories',
            type: 'item',
            url: '/pages/product-sub-categories/product-sub-categories-list',
            target: false,
            icon: icons.LocalMoviesIcon,
            breadcrumbs: false
        },
      
       
    ]
};

export default utilities;
