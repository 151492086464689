import dashboard from './dashboard';
import utilities from './utilities';
import reports from './reports';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities, reports]
};

export default menuItems;
