// assets
import { IconDashboard } from '@tabler/icons';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

// constant
const icons = { IconDashboard, DashboardOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/pages/dashboard',
            icon: icons.DashboardOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
