import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { RESET_USER } from 'store/actions';
import config from 'config';
import { localStorageUtil } from 'ui-component/reusableComponents/CommonFunctions/localStorageUtil';


const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const str1 = customization?.userInfo?.role;
    const str2 = str1 === 'superadmin' ? customization?.userInfo?.username : customization?.userInfo?.name;

    const hqName = localStorageUtil.getItem('hqName');
    const hqImage = localStorageUtil.getItem('hqImage');

    const userRole = str1;
    const userName = str2;
    const navigate = useNavigate();
    const dispatch = useDispatch();
 
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleLogout = async () => {
        localStorageUtil.deleteItem('storeName');
        localStorageUtil.deleteItem('hqstoreUuid');
        localStorageUtil.deleteItem('hqToken');
        localStorageUtil.deleteItem('headquarters');
        localStorageUtil.deleteItem('hqImage');
        localStorageUtil.deleteItem('hqName');
        navigate('/store-login');
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                // icon={
                //     <Avatar
                //         src={`${config.host}${hqImage[0].imageURL}`}
                //         alt={hqName}
                //         sx={{
                //             ...theme.typography.mediumAvatar,
                //             margin: '8px 0 8px 8px !important',
                //             cursor: 'pointer',
                //             textTransform: 'capitalize'
                //         }}
                //         ref={anchorRef}
                //         aria-controls={open ? 'menu-list-grow' : undefined}
                //         aria-haspopup="true"
                //         color="inherit"
                //     />
                // }
                icon={
                    hqImage && hqImage.length > 0 ? (
                   
                      <Avatar
                        src={`${config.host}${hqImage[0].imageURL}`}
                        alt={hqName}
                        sx={{
                          ...theme.typography.mediumAvatar,
                          margin: '8px 0 8px 8px !important',
                          cursor: 'pointer',
                          textTransform: 'capitalize'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                      />
                    ) : (
                    
                      <Avatar
                        alt={hqName}
                        sx={{
                          ...theme.typography.mediumAvatar,
                          margin: '8px 0 8px 8px !important',
                          cursor: 'pointer',
                          textTransform: 'capitalize'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                      />
                    )
                  }
               
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" sx={{ mb: '10px' }}>Hi Headquarters,</Typography>
                                                <Typography
                                                    component="span"
                                                    variant="h4"
                                                    sx={{ fontWeight: 400, textTransform: 'capitalize' }}
                                                >
                                                    {userName}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: '10px' }}>

                                                Role: Headquarters
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>

                                                Store Name: {hqName}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        {/* <Box sx={{ p: 2 }}> */}
                                        <Box >
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/pages/hq-settings')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton> */}

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
