import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const StoreLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/StoreLogin')));
const Test = Loadable(lazy(() => import('views/pages/test/index')));
const Example = Loadable(lazy(() => import('views/pages/example/index')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/privacy-policy/index')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/pages/register/register',
            element: <AuthRegister />
        },
        {
            path: '/store-login',
            element: <StoreLogin />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: '/hq-login',
            element: <StoreLogin />
        },
        {
            path: '/pages/test',
            element: <Test />
        },
        {
            path: '/pages/example',
            element: <Example />
        }
    ]
};

export default AuthenticationRoutes;
