// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
// userInfo
export const SET_USER_INFO = '@customization/SET_USER_INFO';
export const RESET_USER = '@customization/RESET_USER';
export const SET_STORE_INFO = '@customization/SET_STORE_INFO'

export const SET_LAB_INFO = '@customization/SET_LAB_INFO';
export const SET_DEPARTMENT_INFO = '@customization/SET_DEPARTMENT_INFO';

export const SET_STORE_CATEGORIES = '@customization/SET_STORE_CATEGORIES';
export const SET_PRODUCT_CATEGORIES = '@customization/SET_PRODUCT_CATEGORIES';
export const SET_ZONES = '@customization/SET_ZONES';
export const SET_LOADING = '@customization/SET_LOADING';
export const SET_BRANCHES = '@customization/SET_BRANCHES'
export const SET_PRODUCTS = '@customization/SET_PRODUCTS'
export const SET_BRANCH_ORDER = '@customization/SET_BRANCH_ORDER'
