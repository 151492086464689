import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import BranchLayout from 'layout/BranchLayout';

// dashboard routing

const BranchDashboard = Loadable(lazy(() => import('views/dashboard/Branch')));
const PagesSettings = Loadable(lazy(() => import('views/pages/settings')));

// Menu Pages routing

const PagesBranchOrders = Loadable(lazy(() => import('views/pages/branches/orders/index')));
const PagesBranchProducts = Loadable(lazy(() => import('views/pages/branches/products/index')));
const PagesCreateOrder = Loadable(lazy(() => import('views/pages/branches/orders/create')));
const PagesProductsReports = Loadable(lazy(() => import('views/pages/branches/reports/products/index')));
const PagesOrderReports = Loadable(lazy(() => import('views/pages/branches/reports/orders/index')));
const PagesViewOrder = Loadable(lazy(() => import('views/pages/branches/orders/view')));

const PagesSuppliers = Loadable(lazy(() => import('views/pages/branches/suppliers/index')));
const PagesSuppliersCreateEdit = Loadable(lazy(() => import('views/pages/branches/suppliers/form')));
const PagesSuppliersView = Loadable(lazy(() => import('views/pages/branches/suppliers/view')));

const PagesPurchase = Loadable(lazy(() => import('views/pages/branches/purchasebill/index')));
const PagesCreatePurchase = Loadable(lazy(() => import('views/pages/branches/purchasebill/create')));
const PagesViewPurchase = Loadable(lazy(() => import('views/pages/branches/purchasebill/view')));


const PageSales = Loadable(lazy(() => import('views/pages/branches/salesbill/index')));
const PageSalesView = Loadable(lazy(() => import('views/pages/branches/salesbill/view')));
const PageCreateSales = Loadable(lazy(() => import('views/pages/branches/salesbill/create')));


const BranchRoutes = {
    path: '/',
    element: <BranchLayout />,
    children: [
        {
            path: '/pages/branch-dashboard',
            element: <BranchDashboard />
        },
        {
            path: '/pages/branch-orders',
            element: <PagesBranchOrders />
        },
        {
            path: '/pages/branch-products',
            element: <PagesBranchProducts />
        },

        {
            path: 'pages/branch-reports/orders',
            element: <PagesOrderReports />
        },
        {
            path: 'pages/branch-reports/products',
            element: <PagesProductsReports />
        },
        {
            path: '/pages/branch-settings',
            element: <PagesSettings />
        },
        {
            path: '/pages/branch-order/create-order',
            element: <PagesCreateOrder />
        },
        {
            path: '/pages/branch-order/view',
            element: <PagesViewOrder />
        },
        {
            path: '/pages/branch-dashboard/view-order',
            element: <PagesViewOrder />
        },

        {
            path: '/pages/branch-suppliers',
            element: <PagesSuppliers />
        },
        {
            path: '/pages/branch-suppliers/create-supplier',
            element: <PagesSuppliersCreateEdit/>
        },
        {
            path: '/pages/branch-suppliers/update-supplier',
            element: <PagesSuppliersCreateEdit/>
        },
        {
            path: '/pages/branch-suppliers/view-supplier',
            element: <PagesSuppliersView/>
        },
        {
            path: '/pages/branch-purchasebills',
            element: <PagesPurchase/>
        },
        {
            path: '/pages/branch-purchasebills/create-purchasebill',
            element: <PagesCreatePurchase/>
        },
        {
            path: '/pages/branch-purchasebills/view-purchasebill',
            element: <PagesViewPurchase/>
        }, 
        {
            path: '/pages/branch-salesbills',
            element: <PageSales/>
        },
        {
            path: '/pages/branch-salesbills/view',
            element: <PageSalesView/>
        },
        {
            path: '/pages/branch-salesbills/create-salesbill',
            element: <PageCreateSales/>
        },
    ]
};

export default BranchRoutes;
