import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase
        // fullWidth
        sx={{
            '&.MuiButtonBase-root': {
                width: '55%',
                pointerEvents: 'none',
                p: 0,
                m: 0
            }
        }}
        disableRipple
        // component={Link}  to={config.defaultPath}
    >
        <Logo />
    </ButtonBase>
);

export default LogoSection;
