import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// Menu Pages routing

const PagesSettings = Loadable(lazy(() => import('views/pages/settings')));
const PagesRegister = Loadable(lazy(() => import('views/pages/register')));
const PagesStoreCategoriesList = Loadable(lazy(() => import('views/pages/storeCategories/StoreCategoriesList')));
const PagesProductCategoriesList = Loadable(lazy(() => import('views/pages/productCategories/ProductCategoriesList')));
const PagesProductSubCategoriesList = Loadable(lazy(() => import('views/pages/productSubCategories/ProductSubCategoriesList')));
const PagesZoneList = Loadable(lazy(() => import('views/pages/zone/ZoneList')));
const ViewStore = Loadable(lazy(() => import('views/pages/reports/stores/view')));
const ViewCustomer = Loadable(lazy(() => import('views/pages/reports/customers/view')));

// sample page routing
const AboutPage = Loadable(lazy(() => import('views/about-page')));

// reports
const PagesOrders = Loadable(lazy(() => import('views/pages/reports/orders')));
const PagesStores = Loadable(lazy(() => import('views/pages/reports/stores')));
const PagesCustomers = Loadable(lazy(() => import('views/pages/reports/customers')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/pages/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/pages/reports/orders',
            element: <PagesOrders />
        },
        {
            path: '/pages/reports/stores',
            element: <PagesStores />
        },
        {
            path: '/pages/reports/customers',
            element: <PagesCustomers />
        },
        {
            path: '/pages/settings',
            element: <PagesSettings />
        },
        {
            path: '/about-page',
            element: <AboutPage />
        },
        {
            path: '/pages/store-categories/store-categories-list',
            element: <PagesStoreCategoriesList />
        },
        {
            path: '/pages/product-categories/product-categories-list',
            element: <PagesProductCategoriesList />
        },
        {
            path: '/pages/product-sub-categories/product-sub-categories-list',
            element: <PagesProductSubCategoriesList />
        },
        {
            path: '/pages/zone/zone-list',
            element: <PagesZoneList />
        },
        {
            path: '/pages/hq-register',
            element: <PagesRegister />
        },
        {
            path: '/pages/reports/store/view',
            element: <ViewStore />
        },
        {
            path: '/pages/reports/customer/view',
            element: <ViewCustomer />
        }
    ]
};

export default MainRoutes;
