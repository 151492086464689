import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import HqLayout from 'layout/HqLayout';

// dashboard routing

const HQDashboard = Loadable(lazy(() => import('views/dashboard/Headquarters')));
const PagesSettings = Loadable(lazy(() => import('views/pages/settings')));

// Menu Pages routing

const PagesBranches = Loadable(lazy(() => import('views/pages/headquarters/branches/index')));
const PagesOrders = Loadable(lazy(() => import('views/pages/headquarters/orders/index')));
const PagesProducts = Loadable(lazy(() => import('views/pages/headquarters/products/index')));
const PagesProductsReports = Loadable(lazy(() => import('views/pages/headquarters/reports/products/index')));
const PagesOrderReports = Loadable(lazy(() => import('views/pages/headquarters/reports/orders/index')));
const PagesCreateBranch = Loadable(lazy(() => import('views/pages/headquarters/branches/create')));
const PagesViewBranch = Loadable(lazy(() => import('views/pages/headquarters/branches/view')));
const PagesViewProduct = Loadable(lazy(() => import('views/pages/headquarters/products/view')));
const PagesBranchOrder = Loadable(lazy(() => import('views/pages/headquarters/branches/order')));
const PagesCreateProduct = Loadable(lazy(() => import('views/pages/headquarters/products/create')));
const PagesViewOrder = Loadable(lazy(() => import('ui-component/pageComponents/Order/ViewOrder')));

const PagesSuppliers = Loadable(lazy(() => import('views/pages/headquarters/suppliers/index')));
const PagesSuppliersCreateEdit = Loadable(lazy(() => import('views/pages/headquarters/suppliers/form')));
const PagesSuppliersView = Loadable(lazy(() => import('views/pages/headquarters/suppliers/view')));

const PagesPurchase = Loadable(lazy(() => import('views/pages/headquarters/purchasebill/index')));
const PagesCreatePurchase = Loadable(lazy(() => import('views/pages/headquarters/purchasebill/create')));
const PagesViewPurchase = Loadable(lazy(() => import('views/pages/headquarters/purchasebill/view')));

const PageSales = Loadable(lazy(() => import('views/pages/headquarters/salesbill/index')));
const PageSalesView = Loadable(lazy(() => import('views/pages/headquarters/salesbill/view')));
const PageCreateSales = Loadable(lazy(() => import('views/pages/headquarters/salesbill/create')));


const HqRoutes = {
    path: '/',
    element: <HqLayout />,
    children: [
        {
            path: '/pages/hq-dashboard',
            element: <HQDashboard />
        },
        {
            path: '/pages/hq-branches',
            element: <PagesBranches />
        },
        {
            path: '/pages/hq-orders',
            element: <PagesOrders />
        },
        {
            path: '/pages/hq-products',
            element: <PagesProducts />
        },
        {
            path: 'pages/hq-reports/orders',
            element: <PagesOrderReports />
        },
        {
            path: 'pages/hq-reports/products',
            element: <PagesProductsReports />
        },
        {
            path: '/pages/hq-settings',
            element: <PagesSettings />
        },
        {
            path: '/pages/hq-branches/create-branch',
            element: <PagesCreateBranch />
        },
        {
            path: '/pages/hq-branches/view',
            element: <PagesViewBranch />
        },
        {
            path: '/pages/hq-products/view',
            element: <PagesViewProduct />
        },
        {
            path: '/pages/hq-branches/order',
            element: <PagesBranchOrder />
        },
        {
            path: '/pages/hq-products/create-product',
            element: <PagesCreateProduct />
        },
        {
            path: '/pages/hq-products/update-product',
            element: <PagesCreateProduct />
        },
        {
            path: '/pages/hq-orders/view-order',
            element: <PagesViewOrder />
        },
        {
            path: '/pages/hq-branches/view-order',
            element: <PagesViewOrder />
        },
        {
            path: '/pages/hq-dashboard/view-order',
            element: <PagesViewOrder />
        },
        {
            path: '/pages/hq-suppliers',
            element: <PagesSuppliers />
        },
        {
            path: '/pages/hq-suppliers/create-supplier',
            element: <PagesSuppliersCreateEdit/>
        },
        {
            path: '/pages/hq-suppliers/update-supplier',
            element: <PagesSuppliersCreateEdit/>
        },
        {
            path: '/pages/hq-suppliers/view-supplier',
            element: <PagesSuppliersView/>
        },
        {
            path: '/pages/hq-purchasebills',
            element: <PagesPurchase/>
        },
        {
            path: '/pages/hq-purchasebills/create-purchasebill',
            element: <PagesCreatePurchase/>
        },
        {
            path: '/pages/hq-purchasebills/view-purchasebill',
            element: <PagesViewPurchase/>
        }, 
        {
            path: '/pages/hq-salesbills',
            element: <PageSales/>
        },
        {
            path: '/pages/hq-salesbills/view',
            element: <PageSalesView/>
        },
        {
            path: '/pages/hq-salesbills/create-salesbill',
            element: <PageCreateSales/>
        },

    ]
};

export default HqRoutes;
