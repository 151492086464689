import { useEffect } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavigationScroll from 'layout/NavigationScroll';
import { useSelector, useDispatch } from 'react-redux';
import Routes from 'routes';
import themes from 'themes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'store';
import './App.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SET_LOADING, SET_USER_INFO } from 'store/actions';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const isLoading = customization.isLoading;
    return (
        <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                        {isLoading ? (
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open
                                onClick={(reason) => reason && reason === 'backdropClick'}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        ) : (
                            ''
                        )}
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </PersistGate>
    );
};

export default App;
