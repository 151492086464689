const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 10,
    appName: 'Samrudhi',

    appVersionProd: '1.0.0',
    appVersionUat: '1.0.0',

    // host: 'http://103.208.228.42:8019',
    // host: 'https://app.winkatbuy.com',
    // host: 'http://app.winkatbuy.com',
    // host: 'http://192.168.1.101:8012',
    // host: 'http://103.208.228.42:8019',
    // host: 'http://192.168.1.153:8019',
    host: 'https://app.winkatbuy.com',

    mapKey: 'AIzaSyC6O2CG9zBAksJH4SCgCZlbx3XRWVwzY1E',
    library: ['drawing', 'places']
};

export default config;
