// material-ui
import Logo1 from '../assets/images/background/StoreAggLogo.svg';
import Logo2 from '../assets/images/background/samruddhi.png'


/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

 const Logo = () => (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */

    // <img src={Logo1} title="Samruddhi" alt="Samruddhi" height="50px" />
    <img src={Logo2} title="Samruddhi" alt="Samruddhi" height="50px" width="100px"  />
);

export default Logo;
