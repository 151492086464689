// project imports
import config from 'config';

// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    isOpen: [],
    isLoading: false, // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    userInfo: null,
    labInfo: null,
    departmentInfo: null,
    storeCategoriesInfo: null,
    productCategoriesInfo: null,
    zonesInfo:null,
    branchesInfo:null,
    storeInfo:null,
    productsInfo:null,
    orderInfo:null,
};
// ==============================|| CUSTOMIZATION REDUCER ||============================== //
const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            };
            case actionTypes.SET_STORE_INFO:
                return {
                    ...state,
                    storeInfo: action.storeInfo
                };
        case actionTypes.SET_LAB_INFO:
            return {
                ...state,
                labInfo: action.labInfo
            };
        case actionTypes.SET_DEPARTMENT_INFO:
            return {
                ...state,
                departmentInfo: action.departmentInfo
            };
        case actionTypes.SET_STORE_CATEGORIES:
            return {
                ...state,
                storeCategoriesInfo: action.storeCategoriesInfo
            };
        case actionTypes.SET_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategoriesInfo: action.productCategoriesInfo
            };
            case actionTypes.SET_ZONES:
            return {
                ...state,
                zonesInfo: action.zonesInfo
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

            case actionTypes.SET_BRANCHES:
            return {
                ...state,
                branchesInfo: action.branchesInfo
            };
            case actionTypes.SET_BRANCH_ORDER:
                return {
                    ...state,
                    branchorderInfo: action.branchorderInfo
                };
            case actionTypes.SET_PRODUCTS:
                return {
                    ...state,
                    productsInfo: action.productsInfo
                };
        case actionTypes.RESET_USER:
            return initialState;
        default:
            return state;
    }
};

export default customizationReducer;
