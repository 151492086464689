// assets
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import OnDeviceTrainingOutlinedIcon from '@mui/icons-material/OnDeviceTrainingOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import { IconBrandChrome, IconHelp, IconReportAnalytics } from '@tabler/icons';
// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconReportAnalytics,
    AssignmentIndOutlinedIcon,
    OnDeviceTrainingOutlinedIcon,
    SummarizeOutlinedIcon,
    AssessmentOutlinedIcon,
    StoreIcon,
    PersonIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    // id: 'sample-docs-roadmap',
    // title: 'Application',
    // caption: 'Know about us',
    // type: 'group',
    // children: [
    //     {
    //         id: 'about-page',
    //         title: 'About',
    //         type: 'item',
    //         url: '/about-page',
    //         icon: icons.IconHelp,
    //         external: false,
    //         target: false,
    //         breadcrumbs: false
    //     }
    // ]
    id: 'reports',
    title: 'Reports',
    caption: 'View your Reports',
    type: 'group',
    children: [
        // {
        //     id: 'summary',
        //     title: 'Summary',
        //     type: 'item',
        //     url: '/pages/reports/summary',
        //     icon: icons.AssessmentOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'userBased',
        //     title: 'User',
        //     type: 'item',
        //     url: '/pages/reports/userBased',
        //     icon: icons.AssignmentIndOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'deviceBased',
        //     title: 'Device',
        //     type: 'item',
        //     url: '/pages/reports/deviceBased',
        //     icon: icons.OnDeviceTrainingOutlinedIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/pages/reports/orders',
            icon: icons.SummarizeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'stores',
            title: 'Stores',
            type: 'item',
            url: '/pages/reports/stores',
            icon: icons.StoreIcon,
            breadcrumbs: false
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/pages/reports/customers',
            icon: icons.PersonIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
