import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconReportAnalytics,
    IconUsers,
    IconDeviceAnalytics
} from '@tabler/icons';

import BiotechIcon from '@mui/icons-material/Biotech';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconUsers,
    IconDeviceAnalytics,
    IconReportAnalytics,
    BiotechIcon,
    GroupWorkIcon,
    MyLocationOutlinedIcon,
    StoreMallDirectoryIcon,
    CategoryIcon,
    MapIcon,
    ShoppingCartIcon,
    SummarizeOutlinedIcon,
    LocalShippingIcon,
    ReceiptIcon,
    ReceiptLongIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'pages',
    title: 'Masters',
    caption: 'Manage your Data',
    type: 'group',
    children: [
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/pages/branch-orders',
            target: false,
            icon: icons.SummarizeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/pages/branch-products',
            target: false,
            icon: icons.ShoppingCartIcon,
            breadcrumbs: false
        },
        {
            id: 'suppliers',
            title: 'Suppliers',
            type: 'item',
            url: '/pages/branch-suppliers',
            target: false,
            icon: icons.LocalShippingIcon,
            breadcrumbs: false
        },
        {
            id: 'purchasebill',
            title: 'Purchase Bills',
            type: 'item',
            url: '/pages/branch-purchasebills',
            target: false,
            icon: icons.ReceiptIcon,
            breadcrumbs: false
        },
        {
            id: 'salesbill',
            title: 'Sales Bills',
            type: 'item',
            url: '/pages/branch-salesbills',
            target: false,
            icon: icons.ReceiptLongIcon ,
            breadcrumbs: false
        }
    ]
};

export default utilities;
