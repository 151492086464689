// assets
import { IconDatabase, IconUser } from '@tabler/icons';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
// constant
const icons = {
    IconDatabase,
    IconUser,
    AssessmentIcon,
    ReceiptIcon,
    DescriptionIcon
};

// ==============================|| REPORTS MENU ITEMS ||============================== //

const reports = {
    id: 'reports',
    title: 'Reports',
    caption: 'View your Reports',
    type: 'group',
    children: [
        {
            id: 'managereports',
            title: 'Reports',
            type: 'collapse',
            icon: icons.AssessmentIcon,

            children: [
                {
                    id: 'orderreport',
                    title: 'Order Reports',
                    type: 'item',
                    url: 'pages/branch-reports/orders',
                    target: false,
                    icon: icons.ReceiptIcon
                },
                {
                    id: 'productreport',
                    title: 'Product Reports',
                    type: 'item',
                    url: 'pages/branch-reports/products',
                    target: false,
                    icon: icons.DescriptionIcon
                }
            ]
        }
    ]
};

export default reports;
