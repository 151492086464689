// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    title: 'Application',
    caption: 'Know about us',
    type: 'group',
    children: [
        {
            id: 'about-page',
            title: 'About',
            type: 'item',
            url: '/about-page',
            icon: icons.IconHelp,
            external: false,
            target: false,
            breadcrumbs: false
        }
    ]
};

export default other;
