// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconReportAnalytics,
    IconUsers,
    IconDeviceAnalytics
} from '@tabler/icons';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTiltShift,
    IconUsers,
    IconDeviceAnalytics,
    IconReportAnalytics
};

// ==============================||  UTILITIES MENU ITEMS  ||============================== //

const utilities = {
    id: 'pages',
    title: 'Menu',
    caption: 'Manage your Data',
    type: 'group',
    children: [
        {
            id: 'device-list',
            title: 'Devices',
            type: 'item',
            url: '/pages/devices/device-list',
            target: false,
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        }
        // {
        //     id: 'device-list',
        //     title: 'Sessions',
        //     type: 'item',
        //     url: '/pages/devices/device-list',
        //     target: false,
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'reports',
        //     title: 'Reports',
        //     type: 'item',
        //     url: '/pages/reports',
        //     icon: icons.IconReportAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default utilities;
